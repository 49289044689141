import React from "react";
import MerchPartnerDynamic from "./merchPartnerContent";

function MerchPartner() {
    return <div className="container" id='containerPartner'>
                 <div className="containerCentered">
                    <div className="Title">
                        <h2 id="merchPartner">Merchandise Partner</h2>
                        {/* <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="image-gallery-container">
      <div className="image-gallery">
        <div className="image-container">
          <a href="https://store.nzc.nz/" target="_blank">
            <img src="img/merchPartner/nzc.png" alt="New Zealand Cricket" />
          <p>Go to online store</p>
          </a>
        </div>
        
        <div className="image-container">
          <a href="https://shop.nzfootball.co.nz/" target="_blank">
            <img src="img/merchPartner/nzf.png" alt="New Zealand Football" />
          <p>Go to online store</p>
          </a>
        </div>
        
        <div className="image-container">
          {/* <a href="https://google.com/" target="_blank"> */}
            <img src="img/merchPartner/sf.png" alt="Sport Fan" />
          <p>Go to online store</p>
          {/* </a> */}
        </div>
        
        <div className="image-container">
          <a href="https://nzrlshop.co.nz/" target="_blank">
            <img src="img/merchPartner/nzrl.png" alt="New Zealand Rugby" />
          <p>Go to online store</p>
          </a>
        </div>
      </div>
    </div>
                    {/* <div className="merPartnerContent">
                        <MerchPartnerDynamic />
                    </div> */}
                </div>
           </div>
}

export default MerchPartner;