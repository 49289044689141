import React from "react";
import MerchServicesDynamic from "./merchServicesContent";

function MerchEvents() {
    return <div className="container2" id="background-colour">
                 <div className="containerCentered">
                    <div className="Title">
                        <h2 id="merchServices">Merchandise Events</h2>
                        {/* <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="merPartnerContent">
                        <MerchServicesDynamic />
                    </div>
                </div>
           </div>
}

export default MerchEvents;