// ESTE CODIGO ES PARA QUE SOLO HABRA EL MODAL DEL ID 1

import React, { useState, useEffect } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { merchServices_data } from './data.js';

export function MerchServices(props) {
  const [modalShow, setModalShow] = useState(false);

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleOpenModal = () => {
    if (props.id === 1) {
      setModalShow(true);
    }
  };

  return (
    <div className={"merchPartner"} id={props.title + props.id} tabIndex={props.tabIndex}>
      <div className="responsiveMerchPartner">
        <a href={props.link} target="_blank" onClick={handleOpenModal}>
          <img className="imagenMerchPartner" src={props.imgCourse} alt="Merchandising" />
        </a>
      </div>
      <div className="responsiveMerchPar">
        <h3>{props.title}</h3>
        <p className="infocourse1">{props.info}</p>
      </div>

      {/* Modal */}
      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.carousel}
          <p>{props.info}</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseModal}>Cerrar</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function MerchServicesDynamic() {
  const [merchServices, setMerchServices] = useState([]);

  useEffect(() => {
    setMerchServices(merchServices_data);
  }, []);

  const merchServicesComponents = merchServices.map((merchServices) => (
    <MerchServices
      key={"merchServices " + merchServices.id}
      id={merchServices.id}
      title={merchServices.title}
      info={merchServices.info}
      imgCourse={merchServices.imgCourse}
      tabIndex={merchServices.id}
      // link={merchPartner.link}
      carousel={merchServices.carousel}
    />
  ));

  return (
    <main>
      <div className="merchPartner-container">
        {merchServicesComponents}
      </div>
    </main>
  );
}

export default MerchServicesDynamic;





// ESTE CODIGO ES PARA QUE HABRAN TODOS LOS MODALES


// import React, { useState, useEffect } from "react";
// import { Carousel, Modal } from "react-bootstrap";
// import { merchServices_data } from './data.js';

// export function MerchServices(props) {
//   const [modalShow, setModalShow] = useState(false);

//   const handleCloseModal = () => {
//     setModalShow(false);
//   };

//   const handleOpenModal = () => {
//     setModalShow(true);
//   };

//   return (
//     <div className={"merchPartner"} id={props.title + props.id} tabIndex={props.tabIndex}>
//       <div className="responsiveMerchPartner">
//         <a href={props.link} target="_blank" onClick={handleOpenModal}>
//           <img className="imagenMerchPartner" src={props.imgCourse} alt="Merchandising" />
//         </a>
//       </div>
//       <div className="responsiveMerchPar">
//         <h3>{props.title}</h3>
//         <p className="infocourse1">{props.info}</p>
//       </div>

//       {/* Modal */}
//       <Modal show={modalShow} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>{props.title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//     {props.carousel}
//           <p>{props.info}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <button onClick={handleCloseModal}>Cerrar</button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// function MerchServicesDynamic() {
//   const [merchServices, setMerchServices] = useState([]);

//   useEffect(() => {
//     setMerchServices(merchServices_data);
//   }, []);

//   const merchServicesComponents = merchServices.map((merchServices) => (
//     <MerchServices
//       key={"merchServices " + merchServices.id}
//       id={merchServices.id}
//       title={merchServices.title}
//       info={merchServices.info}
//       imgCourse={merchServices.imgCourse}
//       tabIndex={merchServices.id}
//     //   link={merchPartner.link}
//     carousel={merchServices.carousel}

//     />
//   ));

//   return (
//     <main>
//       <div className="merchPartner-container">
//         {merchServicesComponents}
//       </div>
//     </main>
//   );
// }

// export default MerchServicesDynamic;
