import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselFWWC() {
  return (
            <Carousel>
                <div>
                <img
                    src="img/Carousel/football/1.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/2.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/3.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/4.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/5.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/6.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/7.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/8.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/9.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
                <div>
                <img
                    src="img/Carousel/football/10.jpg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    {/* <p className="legend">any coment</p> */}
                </div>
               

            </Carousel>

)
}


export default CarouselFWWC;



