import React, { useEffect, useRef, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import MerchPartnerDynamic from "./merchPartnerContent";
// carousel merch partner
import { MerchPartner } from "./merchPartnerContent";
import CarouselFWWC from "./Carousels/carousel-football"
import CarouselCricket from "./Carousels/carousel-cricket";
import CarouselNetball from "./Carousels/carousel-netball";
import CarouselRugby from "./Carousels/carousel-rugby";

//Carousels merch Partner




// const [modalShow, setModalShow] = React.useState(fals 
// const [modalShow2, setModalShow2] = React.useState(false);
    

export let merchPartner_data = [
  

    {
        id: 1,
        title: "New Zealand Football",
        // info: "Some optional text.",
        imgCourse: "img/merchPartner/football.png",
        carousel: <CarouselFWWC />
        // link: setModalShow(true),link: () => setSelectedItem(1),        

    },
    {
        id: 2,
        title: "New Zealand Cricket",
        // info: "Some optional text.",
        imgCourse: "img/merchPartner/cricket.png",
        carousel: <CarouselCricket />
      
        
    },
    {
        id: 3,
        title: "Private Rugby",
        // info: "Some optional text.",
        imgCourse: "img/merchPartner/rugby.png",
        carousel: <CarouselRugby />
     
        
    },
    {
        id: 3,
        title: "Private Netball",
        // info: "Some optional text.",
        imgCourse: "img/merchPartner/netball.png",
        carousel: <CarouselNetball />
      
        
    },

];



export let merchServices_data = [
  

    {
        id: 1,
        title: "FIFA Woman World Cup",
        // info: "Some optional text.",
        imgCourse: "img/merchServices/FWWC.png",
        carousel: <CarouselFWWC />
        // link: setModalShow(true),link: () => setSelectedItem(1),        

    },
    {
        id: 2,
        title: "Woman Rugby World Cup",
        // info: "Some optional text.",
        imgCourse: "img/merchServices/rugby.png",
        // carousel: <CarouselCricket />
        // link: setModalShow2(true),
        
    },
    {
        id: 3,
        title: "ICC Woman Cricket World Cup",
        // info: "Some optional text.",
        imgCourse: "img/merchServices/icc.png",
        carousel: <CarouselRugby />
        // link: "https://www.google.com/",
        
    },
    {
        id: 3,
        title: "Super Rugby",
        // info: "Some optional text.",
        imgCourse: "img/merchServices/Srugby.png",
        carousel: <CarouselNetball />
        // link: "https://www.google.com/",
        
    },

];