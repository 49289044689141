import React, { useState } from "react";

function ExpandableList() {
  const [expandableItems, setExpandableItems] = useState([
    {
      id: 1,
      // title: "MERCHANDISE CONCESSION INFRASTRUCTURE",
      title: "Merchandise concession infrastructure",
      isExpanded: false,
      content: (
        <>
          {/* <p>Contenido del Dropdown 1</p> */}
          <ul>
            <li>Merchandise retail pop-up operations</li>
            <li>Temporary Concession Stands</li>
            <li>Mobile Retail Trailers</li>
            <li>Point of Sale</li>
            <li>Mobile Eftpos</li>
            <li>Retail presentation and display</li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      // "MOBILE RETAIL TRAILER UNIT", 
      title: "Mobile Retail Trailer Unit",
      isExpanded: false,
      content: (
        <>
            <div className="trailer1">
            <img src="img/trailer/trailer1.jpg"></img>
            </div>
        <div className="content-trailer">
          <p>EMS offers a unique retail distribution opportunity of purpose-built mobile merchandise concession trailers. These trailers can be fully operated as self-contained, stand-alone retail operation at any event and location. </p>
          <ul>
            <li>Galvanised R.H.S base frame and chassis</li>
            <li>Walls, ceiling and roof constructed from 50mm poly-panel (consisting of polystyrene and colour steel inside and out)</li>
            <li>Tandem axles with hydraulic override & front axle brakes</li>
            <li>Rear stabilizing jacks</li>
            <li>Double bay awning side lift windows with gas lift struts (length of 1930mm, height of 1150mm)</li>
            <li>120V single phase electrical system with fluorescent lighting</li>
            <li>Tempered safety glass sales display counter (length of 4130mm)</li>
            <li>Multipurpose rear slat wall adjustable display fittings</li>
          </ul>
          <p>Trailers can be branded with your bespoke designed signage ranging from
single colour to all over, full colour vinyl.
</p>
            <ul>
            <li>Header board</li>
            <li>Base Boards</li>
            <li>Side Panels</li>
            <li>Full Wrap Vinyl</li>
            </ul>
            </div>

            <div className="trailer2">
            <img src="img/trailer/trailer2.jpg"></img>
            <img src="img/trailer/trailer3.jpg"></img>
            </div>
        </>
      ),
    },
    {
      id: 3,
      // title: "STAFF AND SUPERVISION",
      title: "Staff and supervision",
      isExpanded: false,
      content: (
        <>
          {/* <p>Contenido del Dropdown 3</p> */}
          
            <ul>
            <li>Event Merchandise Management</li>
            <li>Client Representation and relationship Management</li>
            <li>Vending Staff</li>
            <li>Programme sellers</li>
            <li>Stock controllers</li>
            <li>Cash handling and accounts</li>
            <li>Runners</li>
            <li>Stand security</li>
            </ul>
        </>
      ),
    },
    {
      id: 4,
      // title: "E-COMMERCE ONLINE STORE",
      title: "E-commerce online store",
      isExpanded: false,
      content: (
        <>
          <p>EMS can design, build, and operate direct sale to customers from online stores of your retail licensed and branded merchandise product. 
EMS provides all fulfilment services, warehousing, managing inventory, pick/packing and despatch of all orders, maintaining a high level of customer service.
</p>
<ul>
            <li>A fully responsive online store</li>
            <li>Creative front-end design and build</li>
            <li>Warehouse Integration</li>
            <li>Payment Gateway Integration</li>
            <li>Full sales reconciliation (Royalties paid quarterly)</li>
            </ul>
        </>
      ),
    },
  ]);

  return (
    <div>
      {expandableItems.map((item) => (
        <div key={item.id} className="expandableItem">
          <h3 onClick={() => handleItemClick(item.id)}>{item.title}</h3>
          {item.isExpanded && (
            <div className="dropdownContent">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );

  function handleItemClick(itemId) {
    const updatedItems = expandableItems.map((item) =>
      item.id === itemId
        ? { ...item, isExpanded: !item.isExpanded }
        : item
    );
    setExpandableItems(updatedItems);
  }
}

export default ExpandableList;



// import React, { useState } from "react";
// import EquipmentTable from "./equipmentTable"; 



// function EquipmentItem({ item }) {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const handleItemClick = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div>
//     <div className="equipmentItem" onClick={handleItemClick}>
//       <h3>{item.name}</h3></div>
//       {isExpanded && (
//         <div>
//             <div className="imageContainer">
//                 <img src={item.image} alt={item.name} />
//             </div>
//             <div className="textContainer">
//                 {/* <h3>{item.name}</h3> */}
//                 <p>{item.description}</p>
                
//                 <ul>
//               {item.list.map((listItem, index) => (
//                 <li key={index}>{listItem}</li>
//               ))}
//                 </ul>

//                 {item.name === "Trailers" && <EquipmentTable />}

//             </div>
//         </div>
//       )}
    
//     </div>
//   );
// }

// export default EquipmentItem;


