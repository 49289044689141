import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselRugby() {
  return (
            <Carousel>
                <div>
                <img
                    src="img/Carousel/football/1.jpeg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">any coment</p>
                </div>
                <div>
                <img
                    src="img/Carousel/football/2.jpeg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">any coment</p>
                </div>
                <div>
                <img
                    src="img/Carousel/football/3.jpeg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">any coment</p>
                </div>
                <div>
                <img
                    src="img/Carousel/football/4.jpeg"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">any coment</p>
                </div>
                
            </Carousel>

)
}


export default CarouselRugby;



