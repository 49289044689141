import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function EHire() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedText, setSelectedText] = useState("");

  const handleImageClick = (imageSrc, title, text) => {
    setSelectedImage(imageSrc);
    setSelectedTitle(title);
    setSelectedText(text);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setSelectedTitle("");
    setSelectedText("");
    setShowModal(false);
  };

  return (
    <div className="equipmentHire">
      <div className="container">
        
          <div className="Title">
            <h2 id="equipmentHire">Equipment-Hire Partner</h2>
            <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        
      </div>
      <div className="equipmentContent">
        <section id="scroll">
          <div className="container px-5" id="scroll">
            <div className="row gx-5 align-items-center">
              {/* Sección 1 */}
              <div className="col-lg-6 order-lg-2">
                <div className="p-5">
                  <img
                    className="img-fluid rounded-circle"
                    src="/img/equipment/trailer.jpg"
                    alt="Trailers"
                    onClick={() =>
                      handleImageClick(
                        "/img/equipment/trailer.jpg",
                        "Trailers",
                        "   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="p-5">
                  <h2 className="display-4">Trailers</h2>
                  <p>
                    fuera del cuadro Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam
                    sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione
                    voluptatum molestiae adipisci, beatae obcaecati.
                  </p>
                  <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="scroll2">
          <div className="container px-5" id="scroll2">
            <div className="row gx-5 align-items-center">
              {/* Sección 2 */}
              <div className="col-lg-6">
                <div className="p-5">
                  <img
                    className="img-fluid rounded-circle"
                    src="/img/equipment/posSystem.jpg"
                    alt="POS System"
                    onClick={() =>
                      handleImageClick(
                        "/img/equipment/posSystem.jpg",
                        "POS System",
                        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-5">
                  <h2 className="display-4">POS System</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam
                    sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione
                    voluptatum molestiae adipisci, beatae obcaecati.
                  </p>
                  <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
                </div>
              </div>
            </div>
          </div>
        </section>

      

<section id="scroll">
  <div className="container px-5" id="scroll">
    <div className="row gx-5 align-items-center">
      {/* Sección 3 */}
      <div className="col-lg-6 order-lg-2">
        <div className="p-5">
          <img
            className="img-fluid rounded-circle"
            src="/img/equipment/Eftpos.jpg"
            alt="Eftpos"
            onClick={() =>
              handleImageClick(
                "/img/equipment/Eftpos.jpg",
                "Eftpos",
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-6 order-lg-1">
        <div className="p-5">
          <h2 className="display-4">Eftpos</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit
            iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum
            molestiae adipisci, beatae obcaecati.
          </p>
          <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="scroll2">
  <div className="container px-5" id="scroll2">
    <div className="row gx-5 align-items-center">
      {/* Sección 4 */}
      <div className="col-lg-6">
        <div className="p-5">
          <img
            className="img-fluid rounded-circle"
            src="/img/equipment/display.jpg"
            alt="Display Walls"
            onClick={() =>
              handleImageClick(
                "/img/equipment/display.jpg",
                "Display Walls",
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="p-5">
          <h2 className="display-4">Display Walls</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit
            iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum
            molestiae adipisci, beatae obcaecati.
          </p>
          <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="scroll">
  <div className="container px-5" id="scroll">
    <div className="row gx-5 align-items-center">
      {/* Sección 5 */}
      <div className="col-lg-6 order-lg-2">
        <div className="p-5">
          <img
            className="img-fluid rounded-circle"
            src="/img/equipment/Gazebo.jpg"
            alt="Gazebos"
            onClick={() =>
              handleImageClick(
                "/img/equipment/Gazebo.jpg",
                "Gazebos",
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-6 order-lg-1">
        <div className="p-5">
          <h2 className="display-4">Gazebos</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit
            iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum
            molestiae adipisci, beatae obcaecati.
          </p>
          <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
        </div>
      </div>
    </div>
  </div>
</section>



<section id="scroll2">
  <div className="container px-5" id="scroll2">
    <div className="row gx-5 align-items-center">
      {/* Sección 6 */}
      <div className="col-lg-6">
        <div className="p-5">
          <img
            className="img-fluid rounded-circle"
            src="/img/equipment/table.jpg"
            alt="Tables"
            onClick={() =>
              handleImageClick(
                "/img/equipment/table.jpg",
                "Tables",
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="p-5">
          <h2 className="display-4">Tables</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit
            iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum
            molestiae adipisci, beatae obcaecati.
          </p>
          <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="scroll">
  <div className="container px-5" id="scroll">
    <div className="row gx-5 align-items-center">
      {/* Sección 7 */}
      <div className="col-lg-6 order-lg-2">
        <div className="p-5">
          <img
            className="img-fluid rounded-circle"
            src="/img/equipment/maniquis.jpg"
            alt="Mannequins"
            onClick={() =>
              handleImageClick(
                "/img/equipment/maniquis.jpg",
                "Mannequins",
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati."
              )
            }
          />
        </div>
      </div>
      <div className="col-lg-6 order-lg-1">
        <div className="p-5">
          <h2 className="display-4">Mannequins</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit
            iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum
            molestiae adipisci, beatae obcaecati.
          </p>
          <button
        className="buttonFormat"
        onClick={() =>
          handleImageClick(
            '/img/equipment/trailer.jpg',
            'Trailers',
            '   DENTRO DEL CUADRO  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod aliquid, mollitia odio veniam sit iste esse assumenda amet aperiam exercitationem, ea animi blanditiis recusandae! Ratione voluptatum molestiae adipisci, beatae obcaecati.'
          )
        }
      >
        More Info
      </button>
        </div>
      </div>
    </div>
  </div>
</section>




      </div>

      {/* Pop-up para mostrar la imagen en tamaño completo */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Large image" style={{ width: "100%" }} />
          <p>{selectedText}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EHire;
