import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export let banner_data = [
  {
    id: 1,
    img: "img/home/banner2.jpg",
    link: "https://store.nzc.nz/"
  },
  {
    id: 2,
    img: "img/home/banner3.png",
    link: ""
  },

];

function MerchPartnerComponent() {
  return (
    <div className="merch-partner-container">
      <Carousel autoPlay infiniteLoop showThumbs={false} interval={8000}>
        {banner_data.map((item) => (
             <a href={item.link} target="_blank" rel="noopener noreferrer">
          <div className="topbanner" key={item.id}>
           
            <img className="banner" src={item.img} className="merch-image" alt={`Merch ${item.id}`} />
            
          </div>
          </a>
        ))}
      </Carousel>
    </div>
  );
}




function BannerHome() {
  return (
    <div className="">
      <MerchPartnerComponent />
    </div>
  );
}

export default BannerHome;
