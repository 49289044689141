import React, { useState, useRef } from 'react';
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const Footer = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    email_id: '',
    message: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('Please complete the reCAPTCHA.');
      return;
    }

    // Your EmailJS configuration
    const serviceId = 'service_7w300vg';
    const templateId = 'template_wfkvmmn';
    const userId = 'GxvfuBxUT2vjpmjtQ';

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Reset the form after successful submission
        setFormData({
          first_name: '',
          email_id: '',
          message: '',
        });
        setRecaptchaValue(null);
        recaptchaRef.current.reset();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };


  return (
    <div className="footer" id="contacto">
      <MDBFooter className='text-body text-center text-lg-left'>
        <MDBContainer className='p-4'>
          <MDBRow>
            <MDBCol lg='5' md='12' className='mb-4 mb-md-0 ' id="infoFooter" >
              <div id="contact">
                <img className='logoFooter' src="/img/logo-footer.png" alt="Logo" style={{ width: '330px' }} />
              </div>
              <div className="infocourseFooter" id="infoFooterTitulo2"></div>
              <div className='merchService'>
            <h2>Event Merchandise Services:</h2>
                    <p>support@eventmerchandise.co.nz</p>
               
              </div>
            </MDBCol>

            <MDBCol lg='7' md='12' className='mb-4 mb-md-0'>
              <div className='registerForm'>
                <h2>Get in touch</h2>
                <div className='formApply'>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" as={Col} controlId="validationCustom01">
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        feedback="Please write your first name."
                        // defaultValue=""
                        name="first_name"
                        // id="name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please write your name
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" as={Col} controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          aria-describedby="inputGroupPrepend"
                          required
                          name="email_id"
                          // id="email"

                          value={formData.email_id}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please choose an email.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-4" as={Col} controlId="message">
                      <InputGroup hasValidation>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Message"
                          aria-describedby="inputGroupPrepend"
                          name="message"
                          required
                          // id="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please write a message.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3 justify-content-end"
                        controlId="checkBox1"
                        style={{ color: "white" }}
                        >
                          <div className="recaptcha-container">
                            {/* recapcha for localhost */}
                            {/* <ReCAPTCHA sitekey='6Le0MU8oAAAAAM4zQ8AMbN6ylaqWJP8w-3MwrqMA' onChange={handleRecaptchaChange} /> */}
                            {/* recapcha for EMS website */}
                            <ReCAPTCHA  sitekey='6LdJ9KwoAAAAAATPzGKK72PY-CJ5e-t_eTSUQIgq' onChange={handleRecaptchaChange} />
                          </div>
                    </Form.Group>

                    <div className='btnForm'>
                      <Button id="btn" type="submit">Send</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBCol >
            <br />
            <ul className='list-unstyled links-container' id="listFooter">
              <li className="link-item">
                <a href='#merchPartner' style={{ textDecoration: 'none' }} className='linkFooter'>
                  MERCHANDISE PARTNER
                </a>
              </li>
   
              <li className="link-item">
                <a href='#equipmentHire' style={{  textDecoration: 'none' }} className='linkFooter'>
                  EQUIPMENT HIRE
                </a>
              </li>
              <li className="link-item">
                <a href='#merchServices' style={{  textDecoration: 'none' }} className='linkFooter'>
                  MERCHANDISE EVENTS
                </a>
              </li>
            </ul>
          </MDBCol>
{/* 
          <div className='text-center p-3' style={{ backgroundColor: 'rgba(31,40,87)', color: "white" }}>
            &copy; {new Date().getFullYear()} Event Merchandising Services
          </div> */}
        </MDBContainer>
      </MDBFooter>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(31,40,87)', color: "white" }}>
      Website designed by <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration: 'none', fontWeight: "bold" }}>Falcon Sketch</a> ||  &copy;  {new Date().getFullYear()} - Event Merchandising Services
          </div>
    </div>
  );
}

export default Footer;











