import React from "react";
import EquipmentContent from "./equipmentContent";
import ExpandableList from "./equipmentItem";

function EquipmentHire() {
    return (
        <div className="equipmentHire">
        <div className="container">
            <div className="containerCentered">
                <div className="Title">
                    <h2 id="equipmentHire">MERCHANDISE SERVICES</h2>
                    {/* <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                </div>
                 
            </div>  
           
        </div>

        <div className="equipmentContent">
            <ExpandableList />

        {/* <EquipmentContent />  */}

        
        </div> 
        </div> 
    );
}

export default EquipmentHire;
