
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, Switch, NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './home';
import Header from './header';
import Footer from './footer';
import MerchPartner from './merchPartner';
import MerchEvents from './merchServices';
import EquipmentHire from './equipmentHire';
import Contact from './contactUs';
import EHire from './eHire';
import AboutUs from './aboutUs';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/merchPartner" element={<MerchPartner />}/>
        <Route path="/merchEvents" element={<MerchEvents />} />
        <Route path="/equipmentHire" element={<EquipmentHire />} />
        <Route path="/contactUs" element={<Contact />} />
        <Route path="/eHire" element={<EHire />} />
      </Routes>
      <Footer />
    </Router>
  );
}




export default App;
