import React from "react";

function AboutUs() {

    return <div className="aboutUs">
        <h2>
        Event Merchandise Services (EMS) operates and provides specialised entertainment logistics and merchandise retail services in venues, arenas, entertainment centres and green field sites throughout New Zealand EMS is the premier go-to partner in New Zealand for all your event retail, support services and infrastructure requirements, offering clients with a complete vendor concession service 'one-stop' shop.
        </h2>
                

</div>
        
}

export default AboutUs;