import React from "react";

function Contact() {
    return <div className="">
                <div className="">
                    <h2>Contact </h2>
                </div>
        </div>
}

export default Contact;