import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  return (
    <div>
    <div className="logoNavbar">
        <img src="img/logo-top.png" className="logoMenu" alt="Event Merchandise Services"  />
    </div>
    {/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"> */}
    <Navbar collapseOnSelect expand="lg" variant="dark">
    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-custom" />
    <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto custom-font">    
        <Nav.Link className='nav4 prueba' id='txtblanco' href="#containerPartner">PARTNER</Nav.Link>
        <Nav.Link className='nav4 prueba' id='txtblanco' href="#equipmentHire">SERVICES</Nav.Link>
        <Nav.Link className='nav4 prueba' id='txtblanco' href="#merchServices">EVENTS</Nav.Link>
        <Nav.Link className='nav4 prueba' id='txtblanco' href="#contact">CONTACT</Nav.Link>
          
        </Nav>
    </Navbar.Collapse>
  </Navbar>
</div>
  
  );
}

export default Header;









// import React from 'react';
// import { Navbar, Nav } from 'react-bootstrap';
// import Container from 'react-bootstrap/Container';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// const Header = () => {
//   return (
   
//     <div className='navBar'>



//     <Navbar className='fixed-top-nav' collapseOnSelect expand="lg" variant="dark" >
//     <Container style={{ paddingLeft: '10px', paddingRight: '10px' }}>
//       <Navbar.Brand id='nav2'   href="/" >
//       <img className='logoMenu' src="/img/logotxt2.png" alt="Logo"/>
//       </Navbar.Brand>
//       <Navbar.Toggle aria-controls="basic-navbar-nav">
//         <svg viewBox='0 0 10 8' width='22' height='22'>   
//           <path d='M1 1h8M1 4h8M1 7h8'
//           stroke='#1f2857'
//           strokeWidth='1px'
//           strokeLinecap='round' />
//         </svg>
//       </Navbar.Toggle>
//       {/* <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ Color: '#1f2857' }} /> */}
//       </Container>
//       <Container>
      
      
  
      
//       <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
//         <Nav className="me-auto " >
//           <Nav.Link className='nav4 prueba' id='txtblanco' href="#merchPartner">PARTNER</Nav.Link>
//           <Nav.Link className='nav4 prueba' id='txtblanco' href="#merchServices">SERVICES</Nav.Link>
//           <Nav.Link className='nav4 prueba' id='txtblanco' href="#equipmentHire">EQUIPMENT</Nav.Link>
//           <Nav.Link className='nav4 prueba' id='txtblanco' href="#contact">CONTACT</Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//       </Container>

//   </Navbar>
  
//   </div>
  
//   );
// }

// export default Header;



