import React from "react";
// import { MerchPartner } from "./merchPartnerContent";
import MerchPartner from "./merchPartner";
import MerchEvents from "./merchServices";
import EquipmentHire from "./equipmentHire";
import EHire from "./eHire";
import ContactForm from "./footer2";
import AboutUs from "./aboutUs";
import BannerHome from "./banner";

function Home() {

    return <div className="home">
              {/* <div className="topbanner">
  <a href="https://store.nzc.nz/" target="_blank" rel="noopener noreferrer">
    <img className='banner' src="/img/home/banner2.jpg" alt="Logo" />
  </a>
</div> */}
                
                <BannerHome />
                <AboutUs />
                <MerchPartner />
                <EquipmentHire />
                <MerchEvents />
                {/* <EHire /> */}
                
                

</div>
        
}

export default Home;